.App {
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline white;
}
